@import 'styles/common';

.WelcomeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;

  :global(.button) {
    margin-top: 20px;
  }
}

.shoes {
  width: 248px;
  height: 157px;
  margin-bottom: 15px;
}

.galleryTitle {
  width: 280px;
}

.copy {
  position: relative;
  width: 240px;
  text-align: center;
  font-size: 1rem;
  transform: translateY(10px);
  margin-bottom: -38px;
  p, img {
    opacity: 0;
    transform: translateY(10px);
  }
}

.logos {
  margin: 60px 0 0 40px;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(10px);
  .logo {
    margin: 0 10px;
  }
  .logoDivider {
    border-right: 1px solid $color-gray-dark;
    height: 26px;
    margin: 0 10px;
  }
}

.startContent {
  display: none;
  width: 100%;
  // height: 100%;
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // position: absolute;
  top: 0;
  left: 0;
  > div {
    visibility:  hidden;
  }
}





