@import 'styles/common';

.Artists {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color-gray;
  padding: 0 40px;
  overflow: scroll;

  &.listMode {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    .artistList {
      margin: auto;
    }
  }
}

.artistName {
  &:first-child {
    margin-top: 25px;
  }
  &:last-child {
    margin-bottom: 25px;
  }
  &:not(:last-child):after {
    // want to make it extend global .divider class
    content: '';
    width: 51px;
    border-top: 3px solid $color-gray-dark;
    margin: 25px auto;
    display: block;
  }
}

.social {
  text-align: center;
  margin-bottom: 40px;
  .icon {
    margin: 0 10px;
  }
}

.profileImage {
  $size: 138px;
  width: $size;
  height: $size;
  border-radius: $size;
  margin-top: 85px;
  margin-bottom: 20px;
}

// .artistContent {
//   margin: auto 0;
// }

.artistList {
  &:global(.artist-list-enter),
  &:global(.artist-list-appear) {
    .artistName {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  &:global(.artist-list-enter-active),
  &:global(.artist-list-appear-active) {
    .artistName {
      opacity: 1;
      transition: all 200ms;
      transform: translateY(0);
      @for $i from 1 through 6 {
        &:nth-child(n + #{$i}) {
          transition-delay: $i * 50ms;
        }
      }

    }
  }
  &:global(.artist-list-exit) {
    opacity: 1;
  }
  &:global(.artist-list-exit-active) {
    opacity: 0;
    transition: 300ms;
  }
}