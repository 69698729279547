@import 'styles/common';

.Loader {
  width: 150px;
  height: 127px;

  background-image: url('../../assets/images/spritesheet.png');
  background-size: 2900%, 100%;
  background-position: left;
  background-repeat:no-repeat;

}


@keyframes loader {
  0% { 
    background-position: 0 0; 
  }
  20% {
    background-position: -1950px 0;
  }
  80% {
    background-position: -1950px 0;
  }
  100% { 
    background-position: -4200px 0;
  }
}