/*****************************************
 * slidefade
 *****************************************/
$slidefade-duration: 600ms;
.slidefade,
.slidefade-enter,
.slidefade-exit-done {
  opacity: 0;
  transform: translateY(20px);
}
.slidefade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity $slidefade-duration,
              transform $slidefade-duration;
}
.slidefade-enter-done,
.slidefade-exit {
  opacity: 1;
  transform: translateY(0px);
}
.slidefade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity $slidefade-duration,
              transform $slidefade-duration;
}



.fade-enter,
.fade-appear {
  opacity: 0;
}
.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: 300ms;
}

.fade-up-enter,
.fade-up-appear {
  opacity: 0;
  transform: translateY(10px);
}
.fade-up-enter-active,
.fade-up-appear-active {
  opacity: 1;
  transition: 300ms;
  transform: translateY(0px);
}
.fade-up-exit {
  opacity: 1;
  transform: translateY(0px);
}
.fade-up-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: 300ms;
}

.fade-down-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.fade-down-enter-active {
  opacity: 1;
  transition: 500ms;
  transform: translateY(0px);
}
.fade-down-exit {
  opacity: 1;
  transform: translateY(0px);
}
.fade-down-exit-active {
  opacity: 0;
  transform: translateY(-30px);
  transition: 500ms;
}

.fade-scale-enter {
  opacity: 0;
  transform: scale(.8);
}
.fade-scale-enter-active {
  opacity: 1;
  transition: 500ms;
  transform: scale(1);
}
.fade-scale-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-scale-exit-active {
  opacity: 0;
  transform: scale(.8);
  transition: 500ms;
}

.slide-enter {
  transform: translateY(100%);
}
.slide-enter-active {
  transform: translateY(0);
  transition: 500ms;
}
.slide-exit {
  transform: translateY(0);
}
.slide-exit-active {
  transform: translateY(100%);
  transition: 500ms;
}



