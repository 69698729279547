@import 'styles/common';

.About {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color-gray;
  padding: 90px 40px 50px;
  overflow: scroll;
}

.logos {
  svg {
    margin: 0 10px;
  }
}
