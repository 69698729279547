@import 'styles/common';

.CourtSelected {
  width: 100%;
  display: flex;
  justify-content: center;
  &.centerCourt {
    .courtContainer {
      margin-bottom: 120px;//150px;
      .court {
        transform: translateX(-50%) scale(3.5);
      }
      .pin {
        opacity: 0;
      }
      .arrow {
        opacity: 1;
        transform: translateX(-50%);
      }
    }
  }
  :global(.button) {
    margin: 0 auto;
  }
}

.mainContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 100px; //need to change this to work with all screen sizes
  left: 0;
  width: 100%;
  height: 530px;
}

.copy {
  width: 237px;
  margin: 0 auto;// 20px;
  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  &.centerCourtCopy {
    width: 197px;
  }
}

.selected {
  width: 261px;
  min-height: 89px;
  border: 1px solid $color-gray-dark;
  border-radius: 10px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  @include font-header;
  font-size: 1.25rem;
  line-height: 1.375rem;
  padding: 10px;
  position: relative;
  // page specifig
  margin: 20px auto -45px;
  // end page specific
  span {
    z-index: 2;
    position: relative;
  }
  &:after,
  &:before {
    content: '';
    background-color: $color-gray;
    position: absolute;
    z-index: 1;
    transition: background-color 300ms;
  }
  &:after {
    width: calc(100% + 4px);
    height: 10px;
    left: -2px;
    top: 50%;
    margin-top: -5px;
  }
  &:before {
    height: calc(100% + 4px);
    width: 10px;
    top: -2px;
    left: 50%;
    margin-left: -5px;
  }
}

.courtContainer {
  height: 240px;
  margin-bottom: 45px;
  position: relative;
  width: 100%;
  transition: margin 400ms;
  .court {
    position: absolute;
    transform-origin: center center;
    bottom: 0;
    width: 237px;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 400ms;
  }
  .pin {
    position: absolute;
    width: 87px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
    transition: opacity 200ms;
  }
  .arrow {
    position: absolute;
    width: 107px;
    left: 50%;
    transform: translate(-50%, -20px);
    bottom: 20px;
    transition: opacity 200ms, transform 200ms;
    transition-delay: 400ms;
    opacity: 0;
  }
}

.CourtSelected {
  &:global(.court-selected-enter),
  &:global(.court-selected-appear) {
    .copy, .courtContainer, .selected, :global(.button) {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  &:global(.court-selected-enter-active),
  &:global(.court-selected-appear-active) {
    .copy, .courtContainer, .selected, :global(.button) {
      opacity: 1;
      transition: all 500ms;
      transform: translateY(0);
      transition-delay: 100ms;
    }
    .courtContainer {
      transition-delay: 300ms;
    }
    :global(.button) {
      transition-delay: 500ms;
    }
  }
  &:global(.court-selected-exit) {
    opacity: 1;
  }
  &:global(.court-selected-exit-active) {
    opacity: 0;
    transition: 300ms;
  }
}

