canvas{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: -1px;
}

#loadingContainer, #secondaryLoading {
  font-family: unset;
  h1 {
    margin-bottom: .5rem;
    line-height: 1;
  }
  p {
    font-size: 1.2rem;
  }
  & > div {
    // background: linear-gradient(to bottom right, #0064D2, #0037C0);
    // background: url('../assets/img/gradient-background.png');
    // background-size: cover;
    // background-position: center;
    background-color: $color-gray;
    color: $color-gray-dark;
  }
}

#secondaryLoading, #runtimeErrorContainer {
  // background: url('../assets/img/gradient-background.png');
  background-color: white;
  color: black;
}

#loadBackground {
  background: initial !important;
  .foreground-image {
    // filter: invert(75%);
    // background: black;
    width: auto;
    margin: 0 auto;
    padding: 6px 10px;
  }
}

//header ui
#requestingCameraPermissions {
  color: white;
  background-color: transparent;
}

//change camera icon color
#requestingCameraIcon {
  filter: invert(75%);
}

//loading text and image
#loadImage {
  // width: 50%;
  // height: auto;
  // margin-left: 0;
  display: none;
}

.loadContainer {
  position: absolute;
  padding: 0;
  width: 100%;
  top: 45%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 16px;
  background: transparent !important;
  h2{
    font-size: 2rem;
    line-height: 1.2;
  }
  img{
    width: 160px;
    display: block;
    margin: 0 auto 20px;
  }
  
}

// prompt box
.prompt-box-8w {
  font-family: unset;
  background-color: $color-gray !important;
  padding: 24px;
  // the following properties are not taking
  border-radius: 26px !important;
  box-shadow: 0px 0px 9px -5px rgba(0,0,0,0.88) !important;


  p {
    color: $color-gray-dark;
    font-size: 1.4rem;
  }
}
.prompt-button-8w {
  background-color: transparent !important;
  color: $color-red !important;
  @include font-header;
  text-transform: uppercase;
}

.button-primary-8w {
  color: white !important;
  text-transform: uppercase;
  background-color: $color-red !important;
  @include font-header;
  border-radius: 50px !important;
}


// .permission-error {
//   background: linear-gradient(to bottom right, #0064D2, #0037C0);
// }

.foreground-image {
  width: auto;
  margin: 0 auto;
}
.bottom-message {
  width: 100%;
  text-align: center;
}
#loadingContainer {
  position: fixed;
}
#cameraPermissionsErrorApple {
  p {
    line-height: 1.2;
  }
}
#cameraPermissionsErrorAndroid {
  padding: 40px;
  overflow: auto;
  .loading-error-instructions {
    width: 100%;
    font-size: 16px;
    & > li {
      margin-bottom: 16px;
      &::before {
        top: 0;
        left: -40px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        font-size: 16px;
        line-height: 1.75;
      }
      .foreground-image {
        display: inline-block;
      }
    }
  }
}
#runtimeErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh;
}
.xr-error-icon {
  max-width: 100px;
  margin: 0 auto;
}
#error_text_header_top,
#error_text_header_bottom,
#error_text_header_unknown {
  h2 {
    font-size: 20px;
    line-height: 1.2;
  }
}

#recorder{
  opacity: 0;
  pointer-events: none;
}

.assetsLoaded {
  #recorder {
    opacity: 1;
    pointer-events: all;
    &.fade-container {
      opacity: 0;
      pointer-events: none;
    }
  }

}
.preview-box {
  top: 54%;
}
#actionButton {
  background-color: black;
}
#videoPreview, #imagePreview {
  max-height: calc(73vh - 18vmin);

}

#imagePreview {
  border-radius: 0;
}


#previewContainer {

  .top-bar{
    position: relative;
    margin-top: -60px;
  }

  #customMessage{
    position: absolute;
    width: 70%;
    // bottom: unset;
    bottom: 0;//5%;
    left: 50%;
    transform: translate(-50%, 5px);
    font-size: 14px;
    padding: 20px;
    color: black;
    background-color: white;
    border-radius: 15px;
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      &:first-child{
        margin-top: 0;
      }
    }
  }

}