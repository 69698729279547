.Gallery {
  position: relative;
  z-index: 1000;
  // overflow: hidden;
}
.Loading {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding-top: 135px;
  // text-align: center;
  align-items: center;

  h1 {
    padding: 1.5rem;
    // background-color: white;
  }
}