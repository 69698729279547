@import 'styles/common';

.AccessScreen {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mainContent,
.failContent,
.noLocationContent {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.failContent {
  :global(.button) {
    margin-bottom: 29px;
  }
}

.copy {
  width: 237px;
  margin-bottom: 20px;
  p {
    // font-size: 1.25rem;
    font-size: 1.125rem;
  }
  &.longCopy {
    width: 290px;
  }
}

.icons {
  margin-bottom: 20px;
  text-align: center;
}

.icon {
  margin: 0 20px 20px;
  display: inline-block;
  &:first-child {
    display: block;
    margin: 0 auto;
  }
}

.AccessScreen {
  &:global(.access-enter),
  &:global(.access-appear) {
    .copy, .icons, :global(.button) {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  &:global(.access-enter-active),
  &:global(.access-appear-active) {
    .copy, .icons, :global(.button) {
      opacity: 1;
      transition: all 500ms;
      transform: translateY(0);
      transition-delay: 100ms;
    }
    .icons {
      transition-delay: 300ms;
    }
    :global(.button) {
      transition-delay: 500ms;
    }
  }
  &:global(.access-exit) {
    opacity: 1;
  }
  &:global(.access-exit-active) {
    opacity: 0;
    transition: 300ms;
  }
}

