@import 'styles/common';

.Modal {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.ModalContainer {
  width: 270px;
  background-color: $color-gray;
  border-radius: 26px;
  padding: 58px 24px 50px;
  box-shadow: 0px 0px 9px -5px rgba(0,0,0,0.88);
  position: relative;
}

.close {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 28px;
  &:before,
  &:after {
    content: '';
    transform-origin: center;
    border-top: 6px solid $color-red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}