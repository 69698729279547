* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  // font-size:62.5%;
  // @include font-att-aleck-slab-medium;
  @include font-medium;
  color: $color-gray-dark;
  font-size: 16px;
  line-height: 1.1;
  text-align: center;
  touch-action: pan-x pan-y;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // @include font-att-aleck-slab-black-italic;
  @include font-header;
  line-height: 1.1;
  text-transform: uppercase;
}

h1 {
  font-size: 1.25rem;
}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1.25rem;
}

p, a {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 1.5rem;
}