@import 'styles/common';

.Rotate {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-gray;
  align-items: center;
  justify-content: center;
  z-index: 20000;
  display: none;  
  @media only screen and (orientation: landscape) {
    display: flex;
  }
}
