.button {
  position: relative;
  transition: background-color 300ms;
  // $height: 66px;
  $height: 56px;
  background-color: $color-red;
  width: 209px;
  height: $height;
  border-radius: $height;
  // font-size: 0.875rem;
  @include font-header;
  text-transform: uppercase;
  line-height: $height;
  text-align: center;
  color: $color-gray;
  margin-top: 10px;
  text-decoration: none;;
  &:active {
    background-color: $color-red-light;
  }
  &.outline {
    transition: color 300ms;
    background-color: $color-gray;
    border: solid 1px $color-red;
    color: $color-red;
    span {
      z-index: 2;
      position: relative;
    }
    &:active {
      background-color: $color-gray;
      color: $color-red-light;
    }
    &:after,
    &:before {
      content: '';
      background-color: $color-gray;
      position: absolute;
      z-index: 1;
      transition: background-color 300ms;
    }
    &:after {
      width: calc(100% + 4px);
      height: 10px;
      left: -2px;
      top: 50%;
      margin-top: -5px;
    }
    &:before {
      height: calc(100% + 4px);
      width: 10px;
      top: -2px;
      left: 50%;
      margin-left: -5px;
    }
  }
}

.tester {
  width: 209px;
  height: 33px;
  border-top-left-radius: 33px;
  border-left: solid 1px $color-red;
  border-top: solid 1px $color-red;
  border-bottom-color: transparent;
  background-color: $color-gray;
}