@import 'styles/common';

.CourtsList {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.copy {
  width: 266px;
  margin: 0 auto;// 10px;
  p {
    font-size: 1.125rem;
  }
  &.centerCourtCopy {
    width: 197px;
  }
}

.resultsContainer {
  height: 268px;
  overflow: auto;
  position: relative;
  margin-bottom: 20px;
  padding: 0 10px;
}
.result {
  width: 300px;
  height: 77px;
  // border: 1px solid $color-gray-dark;
  // border-radius: 10px;
  display: flex;
  font-size: 1.125rem;
  line-height: 1.25rem;
  // padding: 15px;
  // padding-top: 23px;
  position: relative;
  margin: 10px 0;
  text-align: left;
  align-items: center;
  transition: background-color 300ms;
  // &.selected {
  //   background-color: $color-gray-dark;
  //   color: $color-gray;
  //   &:after,
  //   &:before {
  //     background-color: $color-gray-dark;
  //   }
  //   &:after {
  //     width: calc(100% + 2px);
  //     left: -1px;
  //   }
  //   &:before {
  //     height: calc(100% + 2px);
  //     top: -1px;
  //   }
  // }
  .pin {
    margin-right: 10px;
    position: relative;
    top: -4px;
  }
  div {
    z-index: 2;
    position: relative;
  }
  .resultLabel {
    width: 200px;
    margin-right: 10px;
    // width: 100%;
    // text-align: center;
  }
  // &:after,
  // &:before {
  //   content: '';
  //   background-color: $color-gray;
  //   position: absolute;
  //   z-index: 1;
  //   transition: background-color 300ms;
  // }
  // &:after {
  //   width: calc(100% + 4px);
  //   height: 10px;
  //   left: -2px;
  //   top: 50%;
  //   margin-top: -5px;
  // }
  // &:before {
  //   height: calc(100% + 4px);
  //   width: 10px;
  //   top: -2px;
  //   left: 50%;
  //   margin-left: -5px;
  // }
}

.CourtsList {
  &:global(.courts-list-enter),
  &:global(.courts-list-appear) {
    .copy, .resultsContainer, :global(.button) {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  &:global(.courts-list-enter-active),
  &:global(.courts-list-appear-active) {
    .copy, .resultsContainer, :global(.button) {
      opacity: 1;
      transition: all 500ms;
      transform: translateY(0);
    }
    .resultsContainer {
      transition-delay: 200ms;
    }
    :global(.button) {
      transition-delay: 400ms;
    }
  }
  &:global(.courts-list-exit) {
    opacity: 1;
  }
  &:global(.courts-list-exit-active) {
    opacity: 0;
    transition: 300ms;
  }
}


