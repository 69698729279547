@import 'styles/common';

.Menu {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  overflow: visible;
  z-index: 100;
  &.expanded {
    .hamburger {
      &:before {
        transform: translateY(13px) rotate(45deg);
      }
      &:after {
        transform: translateY(-13px) rotate(-45deg);
      }
      span {
        opacity: 0;
      }
    }
  }
}

.back {
  position: absolute;
  top: 11px;
  left: 11px;
  svg {
    margin-right: 10px;
    // width: 40px;
    // height: 40px;
    // path,
    // circle {
    //   stroke: $color-red;
    // }
  }
}

.hamburger {
  width: 50px;
  height: 32px;
  position: absolute;
  top: 11px;
  right: 11px;
  &:before,
  &:after,
  span {
    transition: opacity 200ms;
    position: absolute;
    width: 100%;
    border-top: 6px solid $color-red;
    left: 0;
    display: block;
  }
  span {
    top: 50%;
    transform: translateY(-50%);
  }
  &:before,
  &:after {
    transition: transform 200ms;
    content: '';
    transform-origin: center;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
}

.menuContent {
  position: absolute;
  width: 269px;
  height: 362px;
  top: 0;
  right: 0;
  background-color: $color-gray;
  padding: 90px 80px 0 40px;
  box-shadow: 0px 0px 9px -5px rgba(0,0,0,0.88);
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
  .label {
    text-transform: uppercase;
    @include font-header;
    font-size: 1.125rem;
    // line-height: 1.375rem;
    text-align: right;
  }
  .icon {
    margin-left: 10px;
  }
}
