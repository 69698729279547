@import 'styles/common';

.Background {
  background-color: $color-gray;
  width: 100%;
  position: absolute;
  overflow:  hidden;
  opacity: 0;
  transition: opacity 200ms;
  &.loaded {
    opacity: 1;
  }
}

.guide {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-guide {
  position: absolute;
  bottom: 0;
  left: 0;
}
.top-right-container {
  transform-origin: 450px 200px;
  transition: transform 0.5s ease, opacity 0.5s;
  position: absolute;
  top: -50px;
  left: calc(100% - 411px);
}

.red-swoop {
  position: relative;
  top: -13px;
  left: -165px;
}

.teal-swoop {
  position: absolute;
  top: -40px;
  left: -242px;
  transition: transform 0.5s ease;
}
.red-swoop-alt {
  position: absolute;
  // left: calc(100% - 411px);
  // top: 0;
  // left: 0;
  transform: rotate(15deg);
  top: 86px;
  left: 85px;
}

.bottom-left-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}

.blocker-and-blue-swoop {
  // styling for this is kind of wonky because it was switched from SVGs with specific positioning
  position: absolute;
  left: -435px;
  bottom: -108px;
  width: 1007px;
  height: 892px;
}

.blue-swoop {
  position: absolute;
  top: 29px;
  left: 106px;
}

.gray-blocker {
  position: absolute;
  top: -110px;
  left: 278px;
  transition: transform 0.5s ease;
}

.red-checker {
  position: absolute;
  bottom: -10px;
  left: -60px;
}

.swoosh {
  position: absolute;
  bottom: 60px;
  width: 556px;
  height: 197px;
  left: -30px;
  transition: transform 0.5s, opacity 0.5s;
  transform: scale(8);
  opacity: 0;
  path {
    fill: $color-yellow;
  }
}

.altRedSwoop {
  position: absolute;
  transform: translate(-42px, 173px);
  // bottom: -482px;
  // left: -200px;
  bottom: -407px;
  left: -174px;
  transition: transform 0.5s;
}

.initial {
  .swoosh {
    opacity: 1;
    transform: scale(1);
  }
}

.welcome {
  .top-right-container {
    // transform: translate(33px, -239px) rotate(-32deg);
    transform: translate(33px, -281px) rotate(-36deg);
  }
  .teal-swoop {
    transform: translate(-59px, -108px) 
  }
  // .bottom-left-container {  
  //   transform: translate(-115px, 110px)
  // }
  // .altRedSwoop {
  //   transform: none;
  // }
  .bottom-left-container {  
    transform: translate(-145px, 150px)
  }
  .altRedSwoop {
    transform: translate(40px, 90px);
  }
}
.welcome-two {
  @extend .welcome;
 .top-right-container {
   opacity: 0;
   transform: translate(66px, -430px) rotate(-44deg);
 }
 .bottom-left-container {  
   transform: translate(-145px, 150px)
 }
 .altRedSwoop {
   transform: translate(40px, 90px);
 }
}

.no-top {
  .top-right-container {
    opacity: 0;
    transform: translate(66px, -430px) rotate(-44deg);
  }
  .teal-swoop {
    transform: translate(-59px, -108px);
  }
  .bottom-left-container {  
    // transform: translate(-115px, 110px);
   transform: translate(-145px, 150px)
  }
  .gray-blocker {
    transform: translate(39px, 78px) rotate(-29deg);
  }
  .altRedSwoop {
   transform: translate(40px, 90px);
  }
}

.gallery {
  @extend .no-top;
  .bottom-left-container {  
   transform: translate(-295px, 300px)
  }
  .altRedSwoop {
   transform: translate(-40px, 188px);
  }

}

.legals {
  visibility: hidden;
}





