// // Font Faces
// @font-face {
//   font-family: 'ATT Aleck Slab Medium';
//   src: url('../assets/fonts/attaleckslab_md.woff2') format('woff2'),
//        url('../assets/fonts/attaleckslab_md.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Slab Medium Italic';
//   src: url('../assets/fonts/attaleckslab_mdit.woff2') format('woff2'),
//        url('../assets/fonts/attaleckslab_mdit.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Slab Bold';
//   src: url('../assets/fonts/attaleckslab_bd.woff2') format('woff2'),
//        url('../assets/fonts/attaleckslab_bd.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Slab Black Italic';
//   src: url('../assets/fonts/attaleckslab_blkit.woff2') format('woff2'),
//        url('../assets/fonts/attaleckslab_blkit.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Sans Regular';
//   src: url('../assets/fonts/attalecksans_rg.woff2') format('woff2'),
//        url('../assets/fonts/attalecksans_rg.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Sans Bold';
//   src: url('../assets/fonts/attalecksans_bd.woff2') format('woff2'),
//        url('../assets/fonts/attalecksans_bd.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'ATT Aleck Sans Condensed Regular';
//   src: url('../assets/fonts/attaleckcd_rg.woff2') format('woff2'),
//        url('../assets/fonts/attaleckcd_rg.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@import url("//hello.myfonts.net/count/3f5d77");
  
@font-face {
  font-family: "HelveticaNeueLTPro-Md";
  src: url('../assets/fonts/HelveticaNeueLTProMd/font.woff2') format('woff2'), url('../assets/fonts/HelveticaNeueLTProMd/font.woff') format('woff');
}
